<template>
  <van-popup class="tenantPage" v-model:show="visible" position="top" :style="{ width: '100%'}">
    <div class="header">
      {{ title }}
    </div>
    <div class="leadBar">
      <div class="leadItem" v-for="(item, index) in pathInfo.paths">
        <div class="title" :class="{'active':index==pathInfo.paths.length-1}"
             @click="nodeItemMousemoveEvent(0, item, index)"
        >
          {{ item[labelKey] }}
        </div>
        <div class="path" v-if="index!=pathInfo.paths.length-1">
          /
        </div>

      </div>
    </div>

    <div class="listContent">
      <div class="childrenList">
        <div class="cell" v-for="(item, index) in childrenOpenList.openList"
             @click="nodeItemMousemoveEvent(0, item, index)"
        >
          <div class="cellContent">
            {{ item[labelKey] + ` (${item[childKey].length})` }}
          </div>
          <div class="cellIcon">
            <svg-icon width="18px" height="18px" name="arrow-right"/>
          </div>
        </div>
      </div>
      <div class="itemList">
        <div class="cell" v-for="(item, index) in childrenOpenList.itemList"
             @click="pageNodeItemMousemoveEvent(0, item, index)"
        >
          <div class="cellContent" :class="{'cellContentActive':lastItem.id==item.id}">
            {{ item[labelKey] }}
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="cancelButton" @click="cancel">取消</div>
      <div class="sureButton" @click="submit_V2">确定</div>
    </div>

  </van-popup>


</template>

<script>
import filterManager from '@/components/filterPanel/filterItemAction'

export default {
  name: 'tenantPage',
  mixins: [filterManager],
  computed: {
    barList() {

    }
  },
  props: {
    dataValue: {
      default: ''
    },
    title: {
      default: ''
    },
    titleColor: {
      default: 'black'
    },
    typeKey: {
      type: String,
      default: () => {
        return ''
      }
    },
    value: {
      type: [String, Number, Array, Object],
      default: function() {
        return []
      }
    },
    tenantList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 选项值对应参数
    valueKey: {
      type: String,
      default: 'id'
    },
    // 显示标签对应参数
    labelKey: {
      type: String,
      default: 'name'
    },
    childKey: {
      type: String,
      default: 'child'
    },
    searchKey: {
      type: String,
      default: 'name'
    }
  },
  watch: {
    tenantList: {
      handler(value) {
        if (value.length == 0) {
          this.pathInfo.paths = []
          return
        }
        this.panelOptions = value
        let item = JSON.parse(JSON.stringify(this.panelOptions[0]))
        item['checked'] = true
        item['open'] = true
        this.pathInfo.paths = [item]

        if (this.dataValue == '') {
          return
        }
        this.querySearchAsyncById(this.dataValue, (list) => {
          if (list.length == 0) {
            return;
          }
          let item = list[0];
          this.nodeItemMousemoveEvent(0, item, 0)
        })

      }
    }
  },
  data() {
    return {
      visible: false,
      filterableValue: '',
      panelOptions: [],
      searchPanelOptions: []
    }
  },
  methods: {
    showOrHidden: function(visible) {
      this.visible = visible
    },
    changeEvent(e) {
      this.$emit('changeValue', e)
      this.$emit('change', e)
    },
    querySearchAsyncById: function(queryString, cb) {
      if (queryString == '') {
        cb([])
        return
      }

      let findItem = (dataList, list, queryString) => {
        if (dataList.length == 0) {
          return list
        }

        let children = []

        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          if (this.$valueIsExist(data, this.childKey)) {
            children = children.concat(data[this.childKey])
          }
          let id = data[this.valueKey]
          if (id != queryString) {
            continue
          }
          list.push(data)
        }
        return findItem(children, list, queryString)
      }
      try {
        let list = findItem(JSON.parse(JSON.stringify(this.panelOptions)), [], queryString)
        cb(list)
      } catch (e) {
        cb([])
      }
    },

    querySearchAsync: function(queryString, cb) {
      if (queryString == '') {
        cb([])
        return
      }

      let findItem = (dataList, list, queryString) => {
        if (dataList.length == 0) {
          return list
        }

        let children = []

        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          if (this.$valueIsExist(data, this.childKey)) {
            children = children.concat(data[this.childKey])
          }
          let name = data[this.labelKey]
          if (name.indexOf(queryString) < 0) {
            continue
          }
          list.push(data)
        }
        return findItem(children, list, queryString)
      }
      try {
        let list = findItem(JSON.parse(JSON.stringify(this.panelOptions)), [], queryString)
        cb(list)
      } catch (e) {
        cb([])
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.tenantPage {
  height: 100%;

  .header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    position: fixed;
    top: 0px;
  }

  .leadBar {
    position: fixed;
    top: 40px;
    width: calc(100%);
    height: 30px;
    overflow-x: scroll;
    padding: 0px 10px 0px 10px;
    border-bottom: 1px solid $border_color1;
    display: flex;
    flex-direction: row;

    .leadItem {
      height: 30px;
      flex-shrink: 0;

      .title {
        float: left;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
      }

      .path {
        float: left;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
        margin: 0px 5px 0px 5px;
      }

      .active {
        color: $color_primary;
      }

    }

  }

  .listContent {
    position: absolute;
    top: 70px;
    width: 100%;
    background-color: $background_color1;
    height: calc(100% - 120px);
    overflow: scroll;

    .childrenList {
      background-color: white;
      margin-top: 10px;
    }

    .itemList {
      background-color: white;
      margin-top: 10px;
    }

    .cell {
      width: calc(100% - 20px);
      padding-left: 10px;
      height: 36px;
      border-bottom: 1px solid $border_color1;

      .cellContent {
        width: calc(100% - 40px);
        float: left;
        height: 36px;
        line-height: 36px;
      }

      .cellContentActive {
        color: $color_primary;
      }


      .cellIcon {
        width: 18px;
        float: right;
        height: 18px;
        margin-right: -10px;
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);
      }

    }
  }

  .footer {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0px;
    border-top: 1px solid $border_color1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .cancelButton {
      background-color: $color4;
      width: 100px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      text-align: center;
    }

    .sureButton {
      background-color: $color_primary;
      width: 100px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      color: white;
      font-size: 14px;
      text-align: center;
    }
  }

}


::-webkit-scrollbar {
  display: none;
}

</style>